import images from './images';

const antipasti = [
  {
    title: 'Tagliere di salumi e formaggi locali',
    price: '€16',
    tags: '(Selection of local salami and cheeses from Basilicata)',
  },
  {
    title: 'Tris di formaggi con confettura fatta in casa',
    price: '€10',
    tags: '(Mix of local cheeses with homemade jam)',
  },
  {
    title: 'Cialledda materana',
    price: '€5',
    tags: '(Local specialty: soaked bread, tomatoes, oil, oregano)',
  },
];

const primi = [
  {
    title: 'Orecchiette con cime di rapa e peperone crusco',
    price: '€9',
    tags: '(Orecchiette with broccoli rabe and crispy peppers)',
  },
  {
    title: 'Cavatelli con salsiccia pezzente e funghi cardoncelli',
    price: '€9',
    tags: '(Fresh pasta with sausage and king oyster mushrooms)',
  },
  {
    title: 'Scialatielli con crema di zucca, burrata, capocollo croccante e olive nere',
    price: '€10',
    tags: '(Scialatielli with pumpkin cream, burrata, crispy bacon, and black olives)',
  },
  {
    title: 'Ravioli ripieni di ricotta con pomodoro, salvia e buccia di limone',
    price: '€10',
    tags: '(Ravioli with tomato sauce, ricotta, and lemon peel)',
  },
  {
    title: 'Orecchiette al ragù materano',
    price: '€12',
    tags: '(Lamb, veal, and pork ragù)',
  },
];

const secondi = [
  {
    title: 'Tagliata con funghi cardoncelli e peperone crusco',
    price: '€18',
    tags: '(Sliced steak with king oyster mushrooms and crispy peppers)',
  },
  {
    title: 'Costata di maiale alla griglia con misticanza di verdure',
    price: '€14',
    tags: '(Grilled pork chop with mixed vegetables)',
  },
  {
    title: "Filetto con crema all'Aglianico",
    price: '€19',
    tags: '(Beef tenderloin with Aglianico wine cream)',
  },
];

const dolci = [
  {
    title: 'Tette della monaca con crema chantilly e pistacchio',
    price: '€6',
    tags: "(Nun's breasts with Chantilly cream and pistachio)",
  },
  {
    title: 'Tiramisù classico o al pistacchio',
    price: '€5',
    tags: '(Classic or pistachio tiramisu)',
  },
  {
    title: 'Panna cotta al crusco con crème caramel',
    price: '€5',
    tags: '(Panna cotta with crispy topping and crème caramel)',
  },
  {
    title: 'Sporcamuss con crema pasticcera',
    price: '€5',
    tags: '(Sporcamuss with pastry cream)',
  },
];

const cocktails = [
  {
    title: 'Coca Cola / Fanta',
    price: '€3',
    tags: '(Coca Cola / Fanta)',
  },
  {
    title: 'Chinotto Lurisia',
    price: '€5',
    tags: '(Chinotto Lurisia)',
  },
  {
    title: 'Aperitivo Lurisia',
    price: '€5',
    tags: '(Lurisia aperitif)',
  },
];

const awards = [
{
    imgUrl: images.award01,
    title: 'Rising Star',
  },
  {
    imgUrl: images.award02,
    title: 'Bib Gourmand',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
  },

];

export default { antipasti, primi, secondi, dolci, cocktails, awards };
