import React from "react";
import "./PrivacyPolicy.css"; // Puoi stilizzare questa pagina come preferisci

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="privacy-policy-container">
        <h1>Politica sulla Privacy</h1>
        <p>
          La tua privacy è di fondamentale importanza per noi. Questa politica sulla privacy ha lo scopo di spiegare come raccogliamo, utilizziamo, conserviamo e proteggiamo i tuoi dati personali quando interagisci con il nostro sito web. Ci impegniamo a garantire che la tua esperienza online sia sicura, protetta e conforme alle normative vigenti sulla privacy.
        </p>
        <h2>Raccoglimento e Uso dei Dati Personali</h2>
        <p>
          Raccogliamo informazioni personali che ci fornisci volontariamente quando interagisci con il nostro sito, come ad esempio quando compili un modulo di contatto, prenoti un tavolo o ti iscrivi alla nostra newsletter. Questi dati possono includere il tuo nome, indirizzo email, numero di telefono e altre informazioni necessarie per gestire le tue richieste o comunicazioni.
        </p>
        <p>
          Inoltre, raccogliamo automaticamente alcune informazioni relative al tuo dispositivo e al tuo comportamento di navigazione, come l'indirizzo IP, il tipo di browser, la lingua, la posizione geografica e la durata della visita. Queste informazioni sono utilizzate per migliorare l'esperienza di navigazione, ottimizzare il nostro sito web e per finalità analitiche.
        </p>

        <h2>Uso dei Cookie</h2>
        <p>
          I cookie sono piccoli file di testo che vengono salvati sul tuo dispositivo quando visiti il nostro sito web. I cookie possono essere temporanei (vengono eliminati quando chiudi il browser) o permanenti (restano sul tuo dispositivo per un periodo di tempo più lungo). Utilizziamo i cookie per raccogliere informazioni su come interagisci con il nostro sito web, per migliorare l'usabilità e personalizzare il contenuto in base alle tue preferenze.
        </p>
        <p>
          I cookie ci aiutano anche a ricordare le tue preferenze e impostazioni durante le visite future, consentendoci di offrire una navigazione più fluida e migliorata.
        </p>

        <h2>Tipi di Cookie Utilizzati</h2>
        <ul>
          <li>
            <strong>Cookie Tecnici:</strong> Questi cookie sono necessari per il funzionamento del nostro sito e per garantire che tu possa utilizzare tutte le sue funzionalità, come la navigazione tra le pagine, la prenotazione di un tavolo, o il salvataggio delle tue preferenze.
          </li>
          <li>
            <strong>Cookie di Prestazione:</strong> Questi cookie raccolgono informazioni su come gli utenti interagiscono con il sito, ad esempio, quali pagine vengono visitate più frequentemente. Queste informazioni ci aiutano a migliorare le prestazioni e l'usabilità del nostro sito.
          </li>
          <li>
            <strong>Cookie di Funzionalità:</strong> I cookie di funzionalità ci consentono di ricordare le tue scelte (come la lingua o la regione) per personalizzare la tua esperienza di navigazione.
          </li>
          <li>
            <strong>Cookie di Marketing:</strong> Utilizziamo cookie di marketing per raccogliere informazioni sul tuo comportamento online al fine di mostrarti pubblicità personalizzate che potrebbero esserti di interesse.
          </li>
        </ul>

        <h2>Gestione dei Cookie</h2>
        <p>
          Puoi scegliere di accettare o rifiutare i cookie. Quando visiti il nostro sito, ti verrà mostrato un banner che ti informerà sull'uso dei cookie. Se continui a navigare nel sito, accetti l'uso dei cookie come descritto in questa politica. Se non desideri accettare i cookie, puoi disabilitarli modificando le impostazioni del tuo browser.
        </p>

        <h2>Come Disabilitare i Cookie</h2>
        <p>
          La maggior parte dei browser web accetta automaticamente i cookie, ma puoi modificare le impostazioni del tuo browser per bloccare i cookie o avvisarti quando vengono inviati. Se scegli di disabilitare i cookie, alcune funzionalità del nostro sito potrebbero non funzionare correttamente. Consulta la guida del tuo browser per sapere come gestire o eliminare i cookie.
        </p>

        <h2>Conservazione dei Dati Personali</h2>
        <p>
          Conserviamo i tuoi dati personali solo per il tempo necessario per adempiere agli scopi per i quali sono stati raccolti, a meno che non sia richiesto o consentito dalla legge di conservarli per un periodo più lungo. I tuoi dati saranno trattati in modo sicuro e protetti da accessi non autorizzati.
        </p>

        <h2>Diritti degli Utenti</h2>
        <p>
          Hai il diritto di accedere, correggere, aggiornare o cancellare i tuoi dati personali in qualsiasi momento. Se desideri esercitare uno di questi diritti o hai domande sulla gestione dei tuoi dati personali, puoi contattarci all'indirizzo email fornito nella sezione "Contatti" del nostro sito.
        </p>

        <h2>Modifiche alla Politica sulla Privacy</h2>
        <p>
          Ci riserviamo il diritto di aggiornare questa politica sulla privacy per riflettere eventuali modifiche alle normative o alle pratiche aziendali. Eventuali modifiche saranno pubblicate su questa pagina e, se necessario, ti informeremo tramite un avviso specifico.
        </p>

        <h2>Contatti</h2>
        <p>
          Se hai domande o dubbi sulla nostra politica sulla privacy o sul trattamento dei tuoi dati personali, non esitare a contattarci tramite i canali di comunicazione disponibili sul nostro sito.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
