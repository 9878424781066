import React from "react";

import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
  <div
    className="app__aboutus app__bg flex__center section__padding"
    id="about"
  >
    <div className="app__aboutus-overlay flex__center">
      <img src={images.G} alt="G_overlay" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Chi Siamo</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Da Spoon, crediamo che cenare sia più di un semplice pasto — è un'esperienza.
          Ogni piatto racconta una storia di creatività e dedizione,
          con l'obiettivo di offrire un viaggio culinario memorabile ai nostri ospiti.
        </p>
        <button type="button" className="custom__button">
          Scopri di più
        </button>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">La Nostra Storia</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
         Ciò che è iniziato come un piccolo progetto di passione è cresciuto fino a diventare una destinazione culinaria rinomata per la sua creatività e eccellenza.
           Ogni traguardo del nostro percorso è una testimonianza del nostro amore per il cibo,
            la comunità e l'arte della cucina raffinata.
        </p>
        <button type="button" className="custom__button">
          Scopri di più
        </button>
      </div>
    </div>
  </div>
);

export default AboutUs;