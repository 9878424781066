import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import useNavigate
import {
  AboutUs,
  Chef,
  FindUs,
  Footer,
  Gallery,
  Header,
  Intro,
  Laurels,
  SpecialMenu,
} from "./container";
import { Navbar } from "./components";
import PrivacyPolicy from "./PrivacyPolicy"; // Import the PrivacyPolicy component
import "./App.css";

const App = () => {
  const [showBanner, setShowBanner] = useState(
    localStorage.getItem("cookiesAccepted") === null
  );
  const handleAcceptCookies = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowBanner(false);
    console.log("Cookies accettati");
  };

  const handleRejectCookies = () => {
    localStorage.setItem("cookiesAccepted", "false");
    setShowBanner(false);
    console.log("Cookies rifiutati");
  };

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          {/* Homepage Route */}
          <Route path="/" element={<>
            <Header />
            <AboutUs />
            <SpecialMenu />
            <Chef />
            <Intro />
            <Laurels />
            <Gallery />
            <FindUs />
            <Footer />
          </>} />

          {/* Privacy Policy Route */}
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        </Routes>

        {/* Cookie Consent Banner */}
        {showBanner && (
          <div className="cookie-privacy-banner">
            <p>
              Questo sito utilizza i cookie per migliorare l'esperienza utente.{" "}
              <a href="/privacy-policy">Leggi la nostra politica sulla privacy</a>.
            </p>
            <button onClick={handleAcceptCookies}>Accetta tutti i cookie</button>
            <button onClick={handleRejectCookies}>Rifiuta i cookie</button>
          </div>
        )}
      </div>
    </Router>
  );
};

export default App;
