import React from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiYoutube } from "react-icons/fi";

import { FooterOverlay, Newsletter } from "../../components";
import { images } from "../../constants";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contattaci</h1>
        <p className="p__opensans">Via Bruno Buozzi 59 Sasso Caveoso, 75100, Matera Italia</p>
        <p className="p__opensans">+39 349 402 5300</p>
        <p className="p__opensans">info@lagrotadelgusto.com</p>
      </div>

      <div className="app__footer-links_logo">
         <h1 className="app__footer-headtext">La Grotta Del Gusto</h1>
        <p className="p__opensans">
          &quot;Il miglior modo per trovare te stesso è perderti nel servizio
          agli altri.&quot;
        </p>
        <img alt="spoon__img"
          src={images.spoon}
          className="spoon__img"
          style={{ marginTop: 15 }}/>
        <div className="app__footer-links_icons">
            <div>
                <a target='_blank' href='https://www.instagram.com/la_grotta_del_gusto_matera/'>
                <FiInstagram />
                </a>
            </div>
            <a target='_blank' href="https://www.facebook.com/vitoraimondimatera/">
            <FiFacebook />
            </a>
          <FiTwitter />
          <FiYoutube/>
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Orari di apertura</h1>
        <p className="p__opensans">Lunedì-Venerdì:</p>
        <p className="p__opensans">12:00 - 23:30</p>
        <p className="p__opensans">Sabato-Domenica:</p>
        <p className="p__opensans">10:00 - 23:30</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2024 La Grotta Del Gusto. Tutti i diritti riservati.</p>
    </div>
  </div>
);

export default Footer;
